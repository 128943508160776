import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import ImgNewground from "../../assets/images/Suppliers/newground.png"
import ImgMayfield from "../../assets/images/Suppliers/mayfield.jpg"
import ImgBonners from "../../assets/images/Suppliers/bonners.jpg"
import ImgForestWholeFoods from "../../assets/images/Suppliers/forestwholefoods.jpg"
import ImgFourseasons from "../../assets/images/Suppliers/fourseasons.jpg"
import ImgSeriousTissues from "../../assets/images/Suppliers/serious-tissues.jpg"
import ImgThePorch from "../../assets/images/Suppliers/theporch.jpg"

import { useInView } from "react-intersection-observer"

const SuppliersList = () => {
  const [ref, inView] = useInView()

  return (
    <section className="suppliers--list">
      <Container
        ref={ref}
        style={{
          opacity: inView ? 1 : 0,
          transform: `translateY(${inView ? 0 : 100}px)`,
          transition: "all 1s",
        }}
      >
        <Row className="py-5">
          <Col lg={6} className="suppliers--list__image">
            <img src={ImgNewground} alt="" className="w-100" />
          </Col>
          <Col
            lg={5}
            className="suppliers--list__text d-flex justify-content-center flex-column"
          >
            <h2 className="pb-3">Newground Coffee</h2>
            <p className="">
              Based: Oxford <br />
              <br /> Ethically sourced high-grade coffee, carefully selected
              from small-scale farms around the world, Newground believe coffee
              can go beyond the cup and can be used as a catalyst for change
              right here in our very own communities. They use their business to
              create work and training opportunities for ex-offenders across the
              UK, giving some of the most overlooked people in society a new
              beginning.
            </p>
          </Col>
        </Row>

        <Row className="py-5">
          <Col lg={6} className="suppliers--list__image">
            <img src={ImgMayfield} alt="" className="w-100" />
          </Col>
          <Col
            lg={5}
            className="suppliers--list__text d-flex justify-content-center flex-column"
          >
            <h2 className="pb-3">Mayfield Eggs</h2>
            <p className="">
              Based: Witney, Oxfordshire
              <br />
              <br />
              From early in the morning until dusk, the hens are free to explore
              the fields and dust bathe in the sunshine. The eggs are collected
              by hand each day and checked for quality. Mayfield Eggs are
              members of the ‘Laid in Britain’ assurance scheme which offers the
              highest standards of food safety and bird welfare.
            </p>
          </Col>
        </Row>

        <Row className="py-5">
          <Col lg={6} className="suppliers--list__image">
            <img src={ImgBonners} alt="" className="w-100" />
          </Col>
          <Col
            lg={5}
            className="suppliers--list__text d-flex justify-content-center flex-column"
          >
            <h2 className="pb-3">Bonners Oxford</h2>
            <p className="">
              Based: Oxford <br />
              <br /> An independent family business, based in the historical
              Covered Market Oxford since 1952. Bonners are passionate about
              supporting local farmers and reducing the carbon fruit print
              across the food chain; reducing plastic packaging and doing all
              their central Oxford deliveries on foot (and soon electric bikes!)
              keeping those food miles low. They stock a very wide variety of
              fruit & veg as well as fresh herbs and nuts.
            </p>
          </Col>
        </Row>

        <Row className="py-5">
          <Col lg={6} className="suppliers--list__image">
            <img src={ImgThePorch} alt="" className="w-100" />
          </Col>
          <Col
            lg={5}
            className="suppliers--list__text d-flex justify-content-center flex-column"
          >
            <h2 className="pb-3">The Porch</h2>
            <p className="">
              Based: Oxford <br />
              <br /> The Porch Day Centre targets its services at those who are
              experiencing, or have experienced, rough sleeping, hostel
              accommodation, supported housing, or who are vulnerably housed.
              Porch Preserves is a social enterprise project where their
              artesian jams, chutneys and marmalades are made with ingredients
              from their allotments and donations from Oxford Food Bank.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default SuppliersList
