import React from "react"

import SuppliersHero from "../components/Suppliers/SuppliersHero"
import SuppliersList from "../components/Suppliers/SuppliersList"

import MainLayout from "../layouts/MainLayout"
import { Helmet } from "react-helmet"

const Suppliers = () => {
  return (
    <MainLayout>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="C.Liu" />

        <title>Suppliers | Café Kraken</title>
        <meta
          name="description"
          content="Our food is locally sourced around the Oxford community."
        />
      </Helmet>
      <section className="Suppliers">
        <SuppliersHero></SuppliersHero>
        <SuppliersList></SuppliersList>
      </section>
    </MainLayout>
  )
}

export default Suppliers
