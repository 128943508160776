import React from "react"
import { motion } from "framer-motion"
import { pageFade, pageTransistion } from "../../helpers/FramerMotionAnim"
import { Container } from "react-bootstrap"
import { Parallax } from "react-scroll-parallax"

const SuppliersHero = () => {
  return (
    <motion.div
      initial="out"
      animate="in"
      exit="out"
      variants={pageFade}
      transition={pageTransistion}
    >
      <section className="suppliers--hero">
        <Container className="text-center">
          <h1 className="d-none d-lg-block">
            <Parallax x={[-8, 5]}>
              Our food is{" "}
              <span className="text-monte-carlo"> locally sourced </span>
            </Parallax>{" "}
            <Parallax x={[8, -5]}>around the Oxford community</Parallax>
          </h1>

          <h1 className="d-lg-none">
            Our food is{" "}
            <span className="text-monte-carlo"> locally sourced </span>
            around the Oxford community
          </h1>

          <h6 className="p-5">
            We would like for us all to get a little more in touch with our food
            again and start <br className="d-none d-lg-block" /> thinking about
            where our ingredients come from.
          </h6>
        </Container>
      </section>
    </motion.div>
  )
}

export default SuppliersHero
